import { CHECKBOX_LEF, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_USER_SETTINGS = { action: 'change', subject: 'Notifications' }
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '50px' } },
    { key: 'label', label: 'Profile Name' },
    { key: 'is_active', label: 'Status' },
  ]
  const trashTableColumns = [
    { key: 'actions', label: '', thStyle: { width: '50px' } },
    { key: 'label', label: 'Name' },
    { key: 'is_active', label: 'Status' },
  ]

  const fields = {
    label: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Status',
      rules: 'required',
    },
  }

  return {
    trashTableColumns,
    tableColumns,
    fields,
    ACCESS_ABILITY_USER_SETTINGS,
  }
}
