<template>
  <div class="pb-2">
    <!--    <h4 class="text-center font-medium-5">-->
    <!--      {{ $t('Notification List') }}-->
    <!--    </h4>-->
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="trashTableColumns"
      :is-searchable="false"
      back-to-list-path="settings-users-notifications-list"
      :table-config-options="{
        data: {
          is_active: 0,
        },
      }"
    >
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>
      <template #cell(actions)="{ data }">
        <div
          class="text-nowrap d-flex"
          style="gap: 8px"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom="$t('Delete')"
            icon="LTrashIcon"
            size="16"
            class="featherIcon cursor-pointer border-dotted"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreNotification(data.item.id)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

import { VBTooltip } from 'bootstrap-vue'

import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '../notificationsConfig'

export default {
  name: 'RoleList',
  components: {
    LTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    restoreNotification(data) {
      this.confirmNotification(this, { id: [data], is_active: true }, `${this.MODULE_NAME}/update`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'notification'
    const { trashTableColumns } = tableConfig()
    return {
      trashTableColumns,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
